<template>
  <div>
    <!-- German fiscalization -->
    <german-fiscalization-config />
    <!-- Austrien fiscalization -->
    <austrian-fiscalization-config ref="form" />
  </div>
</template>

<script>
import GermanFiscalizationConfig from './components/german-fiscalization-config'
import AustrianFiscalizationConfig from './components/austrian-fiscalization-config'

export default {
  name: 'Fiscalizations',
  components: {
    GermanFiscalizationConfig,
    AustrianFiscalizationConfig
  },
  methods: {
    async validate(cb) {
      const isValid = await this.$refs.form.validate()
      cb(isValid)
    }
  }
}
</script>
