<template>
  <th-wrapper
    collapsable
    :title="$t('pages.settings.fiscalization.germany.title')"
  >
    <template #subtitle>
      <p>{{ $t('pages.settings.fiscalization.germany.description.one') }}</p>
      <p>
        {{ $t('pages.settings.fiscalization.germany.description.two') }}
        <a
          href="https://portal.tillhub.com/shop/product/sw01tlb082-online-tse-fiskalisierungspaket-957"
          class="text-th-primary"
          target="_blank"
        >
          {{ $t('pages.settings.fiscalization.germany.description.three') }}
        </a>
        {{ $t('pages.settings.fiscalization.germany.description.four') }}
      </p>

      <p>
        {{ $t('pages.settings.fiscalization.germany.description.five') }}
        <a
          class="text-th-primary"
          @click.stop="$router.push({ name: 'resources-registers-all' })"
        >
          {{ $t('pages.settings.fiscalization.germany.description.six') }}
        </a>
        {{ $t('pages.settings.fiscalization.germany.description.seven') }}
      </p>
      <p>
        {{ $t('pages.settings.fiscalization.germany.description.eight') }}
        <a
          href="https://help.unzer.com/kb/guide/de/hardware-und-online-tse-deutschland-8ABlDjCr5S/Steps/2245852"
          class="text-th-primary"
          target="_blank"
        >
          {{ $t('pages.settings.fiscalization.germany.description.nine') }}
        </a>
      </p>
    </template>
  </th-wrapper>
</template>

<script>
export default {
  name: 'GermanFiscalizationConfig'
}
</script>
