<template>
  <th-wrapper
    collapsable
    :title="$t('pages.settings.fiscalization.austria.title')"
  >
    <template #subtitle>
      <p>
        {{ $t('pages.settings.fiscalization.austria.description.one') }}
        {{ $t('pages.settings.fiscalization.austria.description.two') }}
        {{ $t('pages.settings.fiscalization.austria.description.three') }}
      </p>
      <ol class="list-decimal pl-6">
        <li>
          {{ $t('pages.settings.fiscalization.austria.description.four_a') }}
        </li>
        <li>
          {{ $t('pages.settings.fiscalization.austria.description.four_c') }}
        </li>
        <li>
          {{ $t('pages.settings.fiscalization.austria.description.four_d_1') }}
          <a
            target="_self"
            href="/resources/branches/"
            class="text-th-primary"
            v-text="
              $t('pages.settings.fiscalization.austria.description.four_d_2')
            "
          />
          {{ $t('pages.settings.fiscalization.austria.description.four_d_3') }}
        </li>
      </ol>
      <p>
        {{ $t('pages.settings.fiscalization.austria.description.five') }}
        <a
          target="_blank"
          href="https://help.unzer.com/kb/guide/de/fiskalisierung-in-osterreich-U9wcUCpOFS/Steps/3007196"
          class="text-th-primary"
          v-text="$t('pages.settings.fiscalization.austria.description.six')"
        />
      </p>
    </template>
    <el-form ref="form" :model="localForm" :rules="rules">
      <el-row>
        <!-- TID -->
        <el-col :sm="12" :md="12" :lg="8" :xl="4">
          <el-form-item prop="tid" class="mr-4 mb-4">
            <th-input-title
              :info="$t('pages.settings.fiscalization.austria.tid.info')"
              :title="$t('pages.settings.fiscalization.austria.tid.title')"
              :required="isFieldsRequired"
            />
            <el-input id="tid" v-model="tid" class="w-full" data-testid="tid" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <!-- BENID -->
        <el-col :sm="12" :md="12" :lg="8" :xl="4">
          <el-form-item prop="benid" class="mr-4 mb-4">
            <th-input-title
              :info="$t('pages.settings.fiscalization.austria.benid.info')"
              :title="$t('pages.settings.fiscalization.austria.benid.title')"
              :required="isFieldsRequired"
            />
            <el-input
              id="benid"
              v-model="benid"
              class="w-full"
              data-testid="benid"
              :type="isNew ? 'text' : 'password'"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <!-- PIN -->
        <el-col :sm="12" :md="12" :lg="8" :xl="4">
          <el-form-item prop="pin" class="mr-4 mb-4">
            <th-input-title
              :info="$t('pages.settings.fiscalization.austria.pin.info')"
              :title="$t('pages.settings.fiscalization.austria.pin.title')"
              :required="isFieldsRequired"
            />
            <el-input
              id="pin"
              v-model="pin"
              class="w-full"
              data-testid="pin"
              :type="isNew ? 'text' : 'password'"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import get from 'just-safe-get'

const formDefault = () => ({
  tid: null,
  benid: null,
  pin: null
})
export default {
  name: 'AustrianFiscalizationConfig',
  data() {
    return {
      loading: false,
      isNew: false
    }
  },
  computed: {
    ...mapGetters({
      clientConfiguration: 'Config/getClientAccountConfiguration'
    }),
    localForm() {
      return {
        ...formDefault(),
        ...get(this.clientConfiguration, 'settings.fiscally.austria', {})
      }
    },
    tid: {
      get() {
        return get(
          this.clientConfiguration,
          'settings.fiscally.austria.tid',
          null
        )
      },
      set(value) {
        this.updateValue(value, 'tid')
      }
    },
    benid: {
      get() {
        return get(
          this.clientConfiguration,
          'settings.fiscally.austria.benid',
          null
        )
      },
      set(value) {
        this.updateValue(value, 'benid')
      }
    },
    pin: {
      get() {
        return get(
          this.clientConfiguration,
          'settings.fiscally.austria.pin',
          null
        )
      },
      set(value) {
        this.updateValue(value, 'pin')
      }
    },
    isFieldsRequired() {
      return this.tid || this.benid || this.pin
    },
    rules() {
      return {
        tid: [
          {
            required: this.isFieldsRequired,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          {
            min: 8,
            message: this.$t('common.forms.rules.min_length', { length: 8 }),
            trigger: 'blur'
          },
          {
            max: 12,
            message: this.$t('common.forms.rules.max_length', { length: 12 }),
            trigger: 'blur'
          }
        ],
        benid: [
          {
            required: this.isFieldsRequired,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          {
            min: 5,
            message: this.$t('common.forms.rules.min_length', { length: 5 }),
            trigger: 'blur'
          },
          {
            max: 12,
            message: this.$t('common.forms.rules.max_length', { length: 12 }),
            trigger: 'blur'
          }
        ],
        pin: [
          {
            required: this.isFieldsRequired,
            message: this.$t('common.forms.rules.field_warnings.required'),
            trigger: 'blur'
          },
          {
            min: 5,
            message: this.$t('common.forms.rules.min_length', { length: 5 }),
            trigger: 'blur'
          },
          {
            max: 128,
            message: this.$t('common.forms.rules.max_length', { length: 128 }),
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    // if values are null, there were not saved yet
    this.isNew = this.localForm.pin === null || this.localForm.benid === null
  },
  methods: {
    updateValue(value, field) {
      this.$store.dispatch('Config/setClientAccountConfigurationValue', {
        path: `settings.fiscally.austria.${field}`,
        value
      })
    },
    validate() {
      return new Promise((resolve, _) => {
        this.$refs.form.validate((isValid) => {
          if (isValid) {
            // the values are saved, and we set isNew to false so it will not expose the PIN and BENID
            this.isNew = false
          }
          resolve(isValid)
        })
      })
    }
  }
}
</script>
