<template>
  <correspondences />
</template>

<script>
import Correspondences from './correspondences'

export default {
  name: 'Communications',
  components: {
    Correspondences
  }
}
</script>
