<template>
  <th-wrapper
    :title="$t('pages.settings.communications.correspondences.title')"
  >
    <!-- Only correspondences -->
    <div class="mb-8">
      <th-input-title
        :title="
          $t(
            'pages.settings.communications.correspondences.only_correspondences.label'
          )
        "
        :info="
          $t(
            'pages.settings.communications.correspondences.only_correspondences.description'
          )
        "
        class="mb-1"
      />

      <!-- Receipt -->
      <div class="mb-3">
        <el-switch
          v-model="receiptsOnlyCorresondences"
          :active-text="receiptsOnlyCorresondencesSwitchLabel"
        />
      </div>

      <!-- Invoices -->
      <el-switch
        v-model="invoicesOnlyCorresondences"
        :active-text="invoicesOnlyCorresondencesSwitchLabel"
      />
    </div>

    <!-- Strict mode -->
    <div>
      <th-input-title
        :title="
          $t('pages.settings.communications.correspondences.strict_mode.label')
        "
        :info="
          $t(
            'pages.settings.communications.correspondences.strict_mode.description'
          )
        "
        class="mb-1"
      />

      <!-- Receipts -->
      <div class="mb-3">
        <el-switch
          v-model="receiptsStrictMode"
          :active-text="receiptsStrictModeSwitchLabel"
        />
      </div>

      <!-- Invoices -->
      <el-switch
        v-model="invoicesStrictMode"
        :active-text="invoicesStrictModeSwitchLabel"
      />
    </div>
  </th-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'
export default {
  name: 'AccountingDatev',
  computed: {
    receiptsOnlyCorresondences: {
      get() {
        return (
          safeGet(this.$store.state.Config.clientAccountConfiguration, [
            'communications',
            'correspondences',
            'receipts',
            'only_correspondences'
          ]) || false
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: [
            'communications',
            'correspondences',
            'receipts',
            'only_correspondences'
          ],
          value: !!newValue
        })
      }
    },
    receiptsStrictMode: {
      get() {
        return (
          safeGet(this.$store.state.Config.clientAccountConfiguration, [
            'communications',
            'correspondences',
            'receipts',
            'strict_mode'
          ]) || false
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: [
            'communications',
            'correspondences',
            'receipts',
            'strict_mode'
          ],
          value: !!newValue
        })
      }
    },
    invoicesOnlyCorresondences: {
      get() {
        return (
          safeGet(this.$store.state.Config.clientAccountConfiguration, [
            'communications',
            'correspondences',
            'invoices',
            'only_correspondences'
          ]) || false
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: [
            'communications',
            'correspondences',
            'invoices',
            'only_correspondences'
          ],
          value: !!newValue
        })
      }
    },
    invoicesStrictMode: {
      get() {
        return (
          safeGet(this.$store.state.Config.clientAccountConfiguration, [
            'communications',
            'correspondences',
            'invoices',
            'strict_mode'
          ]) || false
        )
      },
      set(newValue) {
        this.$store.dispatch('Config/setClientAccountConfigurationValue', {
          path: [
            'communications',
            'correspondences',
            'invoices',
            'strict_mode'
          ],
          value: !!newValue
        })
      }
    },
    receiptsOnlyCorresondencesSwitchLabel() {
      return this.receiptsOnlyCorresondences
        ? this.$t(
            'pages.settings.communications.correspondences.receipts.only_correspondences.switch.enabled'
          )
        : this.$t(
            'pages.settings.communications.correspondences.receipts.only_correspondences.switch.disabled'
          )
    },
    invoicesOnlyCorresondencesSwitchLabel() {
      return this.invoicesOnlyCorresondences
        ? this.$t(
            'pages.settings.communications.correspondences.invoices.only_correspondences.switch.enabled'
          )
        : this.$t(
            'pages.settings.communications.correspondences.invoices.only_correspondences.switch.disabled'
          )
    },
    receiptsStrictModeSwitchLabel() {
      return this.receiptsStrictMode
        ? this.$t(
            'pages.settings.communications.correspondences.receipts.strict_mode.switch.enabled'
          )
        : this.$t(
            'pages.settings.communications.correspondences.receipts.strict_mode.switch.disabled'
          )
    },
    invoicesStrictModeSwitchLabel() {
      return this.invoicesStrictMode
        ? this.$t(
            'pages.settings.communications.correspondences.invoices.strict_mode.switch.enabled'
          )
        : this.$t(
            'pages.settings.communications.correspondences.invoices.strict_mode.switch.disabled'
          )
    }
  }
}
</script>
